
td.linenos .normal { color: #37474F; background-color: #263238; padding-left: 5px; padding-right: 5px; }
span.linenos { color: #37474F; background-color: #263238; padding-left: 5px; padding-right: 5px; }
td.linenos .special { color: #607A86; background-color: #263238; padding-left: 5px; padding-right: 5px; }
span.linenos.special { color: #607A86; background-color: #263238; padding-left: 5px; padding-right: 5px; }
.demo-highlight .hll { background-color: #2C3B41 }
.demo-highlight { 
    background: #263238; 
    color: #EEFFFF;
 }
.demo-highlight .c { color: #546E7A; font-style: italic } /* Comment */
.demo-highlight .err { color: #FF5370 } /* Error */
.demo-highlight .esc { color: #89DDFF } /* Escape */
.demo-highlight .g { color: #EEFFFF } /* Generic */
.demo-highlight .k { color: #BB80B3 } /* Keyword */
.demo-highlight .l { color: #C3E88D } /* Literal */
.demo-highlight .n { color: #EEFFFF } /* Name */
.demo-highlight .o { color: #89DDFF } /* Operator */
.demo-highlight .p, .demo-highlight .fu
{ color: #ffff9a } /* Punctuation */
.demo-highlight .ch { color: #546E7A; font-style: italic } /* Comment.Hashbang */
.demo-highlight .cm { color: #546E7A; font-style: italic } /* Comment.Multiline */
.demo-highlight .cp { color: #546E7A; font-style: italic } /* Comment.Preproc */
.demo-highlight .cpf { color: #546E7A; font-style: italic } /* Comment.PreprocFile */
.demo-highlight .c1, .demo-highlight .co{ color: #89DDFF; font-style: italic } /* Comment.Single */
.demo-highlight .cs { color: #546E7A; font-style: italic } /* Comment.Special */
.demo-highlight .gd { color: #FF5370 } /* Generic.Deleted */
.demo-highlight .ge { color: #89DDFF } /* Generic.Emph */
.demo-highlight .ges { color: #FFCB6B } /* Generic.EmphStrong */
.demo-highlight .gr { color: #FF5370 } /* Generic.Error */
.demo-highlight .gh { color: #C3E88D } /* Generic.Heading */
.demo-highlight .gi { color: #C3E88D } /* Generic.Inserted */
.demo-highlight .go { color: #546E7A } /* Generic.Output */
.demo-highlight .gp { color: #FFCB6B } /* Generic.Prompt */
.demo-highlight .gs { color: #FF5370 } /* Generic.Strong */
.demo-highlight .gu { color: #89DDFF } /* Generic.Subheading */
.demo-highlight .gt { color: #FF5370 } /* Generic.Traceback */
.demo-highlight .kc { color: #89DDFF } /* Keyword.Constant */
.demo-highlight .kd { color: #BB80B3 } /* Keyword.Declaration */
.demo-highlight .kn { color: #89DDFF; font-style: italic } /* Keyword.Namespace */
.demo-highlight .kp { color: #89DDFF } /* Keyword.Pseudo */
.demo-highlight .kr { color: #BB80B3 } /* Keyword.Reserved */
.demo-highlight .kt { color: #BB80B3 } /* Keyword.Type */
.demo-highlight .ld { color: #C3E88D } /* Literal.Date */
.demo-highlight .m { color: #F78C6C } /* Literal.Number */
.demo-highlight .s { color: #C3E88D } /* Literal.String */
.demo-highlight .na { color: #BB80B3 } /* Name.Attribute */
.demo-highlight .nb { color: #82AAFF } /* Name.Builtin */
.demo-highlight .nc { color: #FFCB6B } /* Name.Class */
.demo-highlight .no { color: #EEFFFF } /* Name.Constant */
.demo-highlight .nd { color: #82AAFF } /* Name.Decorator */
.demo-highlight .ni { color: #89DDFF } /* Name.Entity */
.demo-highlight .ne { color: #FFCB6B } /* Name.Exception */
.demo-highlight .nf { color: #82AAFF } /* Name.Function */
.demo-highlight .nl { color: #82AAFF } /* Name.Label */
.demo-highlight .nn, .demo-highlight .at { color: #e5e5e5 } /* Name.Namespace */
.demo-highlight .nx { color: #EEFFFF } /* Name.Other */
.demo-highlight .py { color: #FFCB6B } /* Name.Property */
.demo-highlight .nt { color: #FF5370 } /* Name.Tag */
.demo-highlight .nv { color: #89DDFF } /* Name.Variable */
.demo-highlight .ow { color: #89DDFF; font-style: italic } /* Operator.Word */
.demo-highlight .pm { color: #89DDFF } /* Punctuation.Marker */
.demo-highlight .w { color: #EEFFFF } /* Text.Whitespace */
.demo-highlight .mb { color: #F78C6C } /* Literal.Number.Bin */
.demo-highlight .mf { color: #F78C6C } /* Literal.Number.Float */
.demo-highlight .mh { color: #F78C6C } /* Literal.Number.Hex */
.demo-highlight .mi{ color: #F78C6C } /* Literal.Number.Integer */
.demo-highlight .mo { color: #F78C6C } /* Literal.Number.Oct */
.demo-highlight .sa { color: #BB80B3 } /* Literal.String.Affix */
.demo-highlight .sb { color: #C3E88D } /* Literal.String.Backtick */
.demo-highlight .sc { color: #C3E88D } /* Literal.String.Char */
.demo-highlight .dl { color: #EEFFFF } /* Literal.String.Delimiter */
.demo-highlight .sd { color: #546E7A; font-style: italic } /* Literal.String.Doc */
.demo-highlight .s2 { color: #C3E88D } /* Literal.String.Double */
.demo-highlight .se { color: #EEFFFF } /* Literal.String.Escape */
.demo-highlight .sh { color: #C3E88D } /* Literal.String.Heredoc */
.demo-highlight .si { color: #89DDFF } /* Literal.String.Interpol */
.demo-highlight .sx { color: #C3E88D } /* Literal.String.Other */
.demo-highlight .sr { color: #89DDFF } /* Literal.String.Regex */
.demo-highlight .s1 { color: #C3E88D } /* Literal.String.Single */
.demo-highlight .ss { color: #89DDFF } /* Literal.String.Symbol */
.demo-highlight .bp { color: #89DDFF } /* Name.Builtin.Pseudo */
.demo-highlight .fm { color: #82AAFF } /* Name.Function.Magic */
.demo-highlight .vc { color: #89DDFF } /* Name.Variable.Class */
.demo-highlight .vg { color: #89DDFF } /* Name.Variable.Global */
.demo-highlight .vi { color: #89DDFF } /* Name.Variable.Instance */
.demo-highlight .vm { color: #82AAFF } /* Name.Variable.Magic */
.demo-highlight .il { color: #F78C6C } /* Literal.Number.Integer.Long */
.demo-highlight .dv{ color: #eff70d }

.python {    
    background-color: rgb(48, 48, 48);
    border: 1px solid rgb(65, 65, 65);
    border-radius: 10px;
    padding: 15px!important;
    white-space: pre;
    
}

.r{    
    /* background-color: rgb(48, 48, 48); */
    background-color: inherit;
    border: 1px solid rgb(48, 48, 48);
    border-radius: 10px;
    /* padding: 1px 15px; */
    white-space: pre;  
}


@media (max-width: 438px){
    .python {
        padding-top: 20px!important;
        padding-bottom:25px!important;
        /* padding: 40px 25px 60px 20px!important; */
    }
}

.output{
    font-size: 20px;
    /* font-style: italic; */
    font-weight: bold;
    color: #0ea5b1;
    margin-top: 0px;
}


p.output-table {
    font-size: 14px;
    /* font-style: italic; */
    font-weight: bold;
    color: #393939;
    margin-top: 10px;
    margin-bottom: 10px;
}

.jp-OutputArea-output {
    margin-top:2px;
    margin-bottom:20px;
}

p {
    margin-top:10px;
    margin-bottom: 10px;
}

.python-table{
    width: 100%;
    border-collapse: collapse;
    font-size:13px;
    border: 1px solid #ddd;
    text-align: center;

}
th, td {
    padding: 3px;
}

.jp-Cell {
    margin-top: 25px;
}

p code {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    font-size:inherit;
}

.sub-title {
    letter-spacing: normal;
    font-size: 20px;
    font-family: Arial;
    margin-bottom: 0.8rem;
    margin-top: 1em;
    color: #368088!important;
}

strong.blue{
    /* color: rgb(145, 117, 74) */
    /* color: rgb(47, 47, 47); */
    color: inherit;
}

div.jp-MarkdownOutput ul {
    margin-top: 0px;
}
div.jp-MarkdownOutput ul li {
    margin-bottom: 10px;
}

div.jp-MarkdownOutput h5{
    margin-bottom: 10px;
    margin-top:20px;
}

#label {
    font-size: 18px;
    padding: 3px 8px;
    font-family: Arial, Helvetica, sans-serif;
    /* color: #222121e0; */
    color: #166162e0;
    font-weight:600;
}
a.quoteLink{
    color: #0282ce;
    text-decoration: underline;
  }
  a.quoteLink:hover{
    color: #004772;
  }

code.sourceCode{
    color: rgb(255, 255, 255)!important;
}
code{
    /* background-color:inherit!important; */
    border:none!important;
    color: #7D12BA;
    /* padding:3px; */
    /* font-size:13px; */
  }

pre > code{
    font-size:18px;
}

pre.rcss {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    padding: 1px 15px;
 
}
code.toggle {
    white-space: pre!important;
}
/* jupyter notebook output table */
div.jp-OutputArea-executeResult table {
    margin-left: 0;
    margin-right: 0;
    border: none;
    border-collapse: collapse;
    color: black;
    font-size: 13px;
    table-layout: fixed;

}
div.jp-OutputArea-executeResult tbody tr:nth-child(odd){
    background: #f5f5f5;
}
div.jp-OutputArea-executeResult thead {
    border-bottom: 1px solid black;
    vertical-align: bottom;
}
div.jp-OutputArea-executeResult tr, th, td {
    vertical-align:middle;
    padding: 0.5em 0.5em;
    line-height: normal;
    white-space: normal;
    max-width: none;
    border: none;

}

div.jp-OutputArea-executeResult p {
    font-size: 14px;
}

/* #header {
    margin-bottom: 30px; 
    padding-bottom: 5px;
    text-align: left; 
    width: 110%;
} */

.tab-content>.tab-pane{display:none}
.tab-content>.active{display:block}

.hide {
    display: none
}

ul.nav {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    list-style: none;
}


.nav-pills > li {
    float: left;
}
.nav-tabs > li {
    float: left;
}
.nav>li {
    position: relative;
    display: block;
}
.nav-pills>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}
.nav-tabs>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}
.nav-pills>li.active>a, 
.nav-pills>li.active>a:hover, 
.nav-pills>li.active>a:focus {
    color: #ffffff;
    background-color: #2fa4e7;
}
.nav-tabs>li.active>a, 
.nav-tabs>li.active>a:hover, 
.nav-tabs>li.active>a:focus {
    color: #ffffff;
    background-color: #2fa4e7;
}
.nav-pills>li>a {
    border-radius: 4px;
}
.nav-tabs>li>a {
    border-radius: 4px;
}
.nav-pills:before,.nav-pills:after{
    content: " ";
    display: table;
    clear: both;
}
.nav-tabs:before,.nav-tabs:after{
    content: " ";
    display: table;
    clear: both;
}

.space{
    margin-left: 5px
}
pre.table{
    padding: 10px 5px 10px 5px!important;;
    /* border-radius: 10px; */
    border:none;
}
table.dataframe{
    font-size:12px!important;
}

.annotation{
    font-size: 14px!important;
}

.bgc-yellow {
    background-color: yellow;
}


.questionOptions {
    border: 3px solid #0054ff08;
    padding-left: 20px;
    background-color: #00ecff0f;
    border-radius: 10px;
    color: #f68e27;
    font-weight: 600;
    font-family: Arial!important;
}

.answers {
    margin-top: 250px
}


.clickable {
    background-color: black;
    color: transparent;
    padding: 2px 15px 2px 15px;
    cursor: pointer;
    transition: background-color 0.5s ease;
    border-radius:10px;
  }

.clicked {
    background-color: #00ecff0f;
    cursor: default;
    color: inherit;
    border-radius:10px;
}
.jp-RenderedMarkdown strong{
    color: inherit
}

.disappear {
    display: none;
}
.showup {
    display: block;
}

.clicktoshow{
    color: #21bde9;
    font-weight: 600;
    text-decoration: underline;
}
.ansi-red-fg{
    color: #E75C58
}

.question-color-green {
    color: #10d410;
    font-weight:bolder;
}
.question-sentence-color-green {
    /* color: #10d410; */
    font-weight:bolder;
}

.explanation {
    border: solid 1px black;
    padding: 15px;
    background-color: #ffffdfd4;
}

/* For WebKit browsers */









/* for R gallery h3 titles */
section.level3>h3 {
    font-size: 28px;
    margin-top: 30px;
}
div.jp-RenderedHTMLCommon>h4{
    font-size: 24px
}

div.cell-output > pre.sourceCode{
    margin-top:0px;
    background-color: #fafafa;
    border: None;
}
div.jp-OutputArea-output > pre.sourceCode {
    margin-top:0px;
    background-color: #fafafa;
    border: None;
}
div.jp-OutputArea-output > pre.sourceCode > code.sourceCode {
    color: black!important;
    background-color: inherit!important;
}
div.cell-output > pre>code.sourceCode{
    color: black!important;
}

div#flex > div > div.cell > div.sourceCode{
    margin: 5px 0px;
}
div#flex > div > div.cell > div.sourceCode > pre.sourceCode{
    border-color: white
}
div#flex > div > div.cell > div.sourceCode > pre.sourceCode>code{
   background-color: inherit!important;
}

.cover-img {
    width: 80%
}
.small-logo{
    /* width: 40%; */
    width: 250px;
}
@media only screen and (min-width: 768px) {
    .small-logo{
        width: 35%;
    }
}

#small-logo{
    width:20%;
}
#small-logo-1{
    width:20%;
}
#small-logo-2{
    width:20%;
}

.quarto-layout-row{
    display: flex;
}

figure#figure > p > img {
    width: 100%
}

#logo-r-regex {
    max-width: 130%;
    width: 130%;
}
.card-image {
    border-radius: 10px;
}
.card-block-size {
    width: inherit;
    height: 110px;
}
.img-size {
    width: 150px;
    
}
div.cell-output.cell-output-stdout > pre.sourceCode{
    margin-bottom: 35px;
}
a > code {
    color: #2fa4e7
}
a > code:hover {
    color: rgb(0, 157, 0)
}
a > strong > code {
    color: #2fa4e7
}
a > strong > code:hover {
    color: rgb(0, 157, 0)
}
div#flex > div.cell{
    margin-right: 5px;
}
h1 > code, h2 > code, h3 > code, h4 > code, h5 > code {
    color: inherit;
    font-size: inherit;
}
h1 > strong > code, h2 > strong > code, h3 > strong > code, h4 > strong > code, h5 > strong > code {
    color: inherit;
    font-size: inherit;
}

.blocks {
    /* display: flex; */
    flex-wrap: wrap;
    flex: 1;
    position: relative
}
.block {
    /* flex-basis: calc(33.333% - 60px); */
    position: relative;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease; 
    background-color: white;
}
.block:hover{
    transform: scale(1.05);
}

.bg-grey{
    background-color: rgb(247 247 247);
}
@media screen and (min-width: 768px) {
    .container {
        flex-direction: column;
    }
    .image, .blocks {
        flex: 1;
        max-width: 100%;
    }
    .blocks {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        position: relative
    }
}

/* .block:active + #detail-block1{
    display: block
} */
.detail-block{
    display: none;
    color: white;
    margin: 30px;
    padding: 20px;
    background-color: #ffa600;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid black;
    width: 30%;
}
.detail-block.active {
    display: block
}
/* .show-block {
    display: block;
} */
#detail-block1{
    position: absolute;
    top: 100px;
    left: 100px
}

#detail-block2{
    position: absolute;
    top: 100px;
    left: 400px
}

.icon{
    position: absolute;
    width: 12%; 
    height: auto;
    top: 5px; 
    left: 0px; 
    opacity: 0.8; 
    transition: all 0.2s ease;
}
@media only screen and (min-width: 460px){
    .fs-xs-24{
        font-size:24px!important;
    }

}

@media only screen and (min-width:510px) {
    .icon{
        width:10%;

    }

}
@media only screen and (min-width:768px) {
    .fs-md-20{
        font-size: 20px;
    }
    .icon{
        width:12%;
    }
    .card-block-size {
        width: inherit;
        height: 120px;
    }
    
   
}
@media only screen and (min-width: 994px){
    .sticky-img{
        position: sticky;
        top:100px;
    }

}

@media only screen and (min-width:1200px) {
    .fs-xl-22{
        font-size:22px;
    }
    .card-block-size {
        width: inherit;
        height: 90px;
    }

}
.rotate-80{
    transform: rotate(80deg);
}

.card-logo-size {
    width: inherit;
    height:200px
}
.highlightGreen {
    color: #008080
}

.blockquote{
    padding-left: 1rem;
    padding-right: 1.5rem;
    color: #5a6570;

    margin-bottom: 1rem;
}
blockquote{
    display: block;
    margin: 0 0 1rem;
    padding: 0.625rem 1.25rem;
    border-left:0.25rem solid #e9ecef;
}

footer > div > a {
    color: grey
}
footer > div > a:hover {
    color:rgb(106, 106, 106)
}




pre.sourceCode::-webkit-scrollbar {
    width: 0px;
}

pre.sourceCode::-webkit-scrollbar-thumb{
  width: 0px;
}

pre.sourceCode:hover::-webkit-scrollbar-thumb {
    background-color: rgba(88, 88, 88, 0.702); 
    width: 2px;
    height: 2px;
    border-radius: 10px;
}
div.cell-output>pre.sourceCode:hover::-webkit-scrollbar-thumb {
    background-color: rgba(218, 218, 218, 0.339); 
    width: 2px;
    height: 2px;
    border-radius: 10px;
}
ul {
    font-size: inherit;
}

li{
    font-size: inherit;
}
.price-container{
    white-space:nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    /* padding-top: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.price-container span {
    display: inline-block; /* Make spans behave like blocks */
    vertical-align: middle; /* Align spans vertically */
}
.back-button{
    background-color: #a9ddfb;
    border: none;
    border-radius: 10px;
    /* width: 100px; */
    padding-top:5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(69, 69, 69);
    font-weight: 700;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
}
.back-button:hover {
    cursor: pointer;
    background-color: #78ceff;
}
.stick-top-left{
    position: fixed;
    transition: top 0.3s
}
.back-button-lg{
    display: none;
}

.back-button-lg:hover{
    cursor: pointer;
    background-color: #78ceff;
}

@media screen and (min-width: 993px) {
    .price-container{
        white-space:nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;
        width: 100%;
        /* padding-top: 30px; */
        display: flex;
        justify-content: flex-start;
    }
    .back-button{
        display: block;
    }
    
    .back-button-lg{
        display: inline-block;
        background-color: #a9ddfb;
        border: none;
        border-radius: 10px;
        width: 160px;
        padding-top:5px;
        padding-bottom: 5px;
        color: rgb(69, 69, 69);
        font-weight: 700;
        font-size: 14px;
        position: fixed;
        top:55px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 9999;
    }

}



.content-not-found{
    position: absolute;
    top: 10%;
    left: 30%;
    z-index:2;

}

.background-bear{
    position: fixed;
    top: 50%;
    left: 50%; /* position the image's top-left corner at the center of the viewport. */
    width: 50%;
    height: auto;
    transform: translate(-50%, -40%);
    z-index: 0;
    pointer-events: none; /* Optional: Allows clicks to pass through the image */
}

/* for PySpark */
#pyspark h3 {
    margin-top: 40px;
}
