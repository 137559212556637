/* CONTENT */
/* INDEX PAGE */
.container-90 {
    max-width: 90%;
}
.container-blog{
    /* max-width:80%;
    margin: 0 auto; */
    display: grid;
    grid-template-columns:1fr;
}
.content-blog-main {
    display: grid;
    grid-template-columns:1fr;
    gap: 0px;
    width: 90%;
    margin: 0 auto;
}

.content-blog {
    /* border: 1px solid green;  */
    margin: auto 10px;

}
.content-blog-main .content-blog h4 {
    /* font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif; */
    font-weight: 900;
}


.content-blog-main .blog-post {
    display: grid;
    /* justify-content: space-between; */
    /* grid-template-columns:5fr 8fr; */
    grid-template-columns:1fr;
    /* height: 350px; */
    border-bottom: 1px solid #e4e4e4b0;
    margin-bottom: 15px;
    padding-bottom: 10px
}

.content-blog-main .blog-post a {
    color: #242424;
}
.content-blog-main .blog-post a:hover{
    color: #242424;
}


.content-blog-main .blog-post .img-container {
    width: 100%;
    /* aspect-ratio: auto 112 / 112; */
    height: 100%;
    overflow: hidden;
    /* margin: auto 5px ; */
    padding: 10px;
    /* justify-self: start; */
    /* white-space: nonwrap; */
    margin: auto 10px;
}
.content-blog-main .blog-post .img-container .blog-img{
    overflow-clip-margin: content-box;
    overflow: clip;

}
.content-blog-main .blog-post .blog-preview{
    padding: 10px;
    /* justify-self: end; */  
    word-wrap: break-word;
    margin: auto 10px;
}


.content-blog-main .blog-post .blog-preview .subtitle{
    margin: 0 0;
    color: rgb(158, 158, 158);
    font-weight: normal;
    font-size: 22px;
    

}
.content-blog-main .blog-post .blog-preview h4 {
    font-size: 1.4rem;
}
.content-blog-main .recent-blog-title{
     margin: 20px;
}
.fc-grey {
    color: #6B6B6B
}
.blog-article .cover-img{
    width: 100%;
    margin: 25px auto;
}

.blog-article header {
    margin: 0 auto;
}
#blog .quarto-figure-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0px auto;
}
figcaption{
    width: 100%;
    margin: 0 auto;
    margin-top: 15px;
    color: grey;
    font-size: 18px;
}
.wideFig{
    width: 100%
}
#blog h3 {
    margin-top: 40px;
}
#blog p{
    margin-bottom: 30px;
}

#ads-in-blog .ads-container {
    text-align: center;
    width: 80%;
    margin: 30px auto;
}
#ads-in-blog .ads-container h5 {
    margin: 15px auto;
    font-size: 24px;
}
#ads-in-blog .ads-container #ggplot2-book {
    max-width: 300px;
}
#ads-in-blog .ads-container .book-star img{
    max-width: 25px;
}
.blog-article-main-title-index{
    font-size: 28px;
    color: rgb(43, 43, 43);
    line-height: 1.2;
    letter-spacing: 0.1px;
}
/* INDEX PAGE */

/* LAYOUT PAGE*/
.blog-main-container {
    margin-top: 40px;
}
.blog-layout-contianer{
    display: grid;
    justify-items: center;
}
.blog-layout-contianer .blog-content{
    /* border: 1px solid green; */
    width: 80%
}
.blog-layout-contianer .blog-button{
    padding-left: 20px;
    /* border: 1px solid green; */
    display: none;
}
.blog-layout-contianer h1 {
    font-size: 1.9rem;
}
.blog-layout-contianer h3 {
    font-size: 1.6rem;
}
/* LAYOUT PAGE*/

@media (min-width: 991px) {
    /* LAYOUT PAGE*/
    .blog-main-container {
        margin-top: 80px;
    }
    .blog-layout-contianer{
        display: grid;
        grid-template-columns: 1fr 11fr 3fr;
        justify-items: end;
    }
    .blog-layout-contianer .blog-content{
        width: 80%;
        display: block;
        margin-right: 10px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .blog-layout-contianer p {
        font-size: 22px;
        line-height: 2.5rem
    }
    .blog-layout-contianer li {
        font-size: 22px;
        line-height: 2.5rem
    }
    .blog-layout-contianer .blog-button {
        display: inline-block
    }
    .blog-layout-contianer h1 {
        font-size: 36px;
    }
    .blog-layout-contianer h3 {
        font-size: 28px;
        margin-top: 50px!important;
        margin-bottom: 20px;
    }
    .blog-layout-contianer .subtitle {
        font-size: 26px;
        color: rgb(158, 158, 158);
    }
    /* LAYOUT PAGE*/

    #ads-in-blog .ads-container h5 {
        margin: 15px auto;

    }
    #ads-in-blog .ads-container .book-star img{
        max-width: 20px;
    }
    #ads-in-blog .ads-container {
        padding-right: 20px;
        padding-left: 20px;
        width: 100%;
        border-left: 1px solid #0e0e0e16;
    }
    #ads-in-blog .ads-container #ggplot2-book {
        max-width: 300px;
    }
    .container-blog{
        /* max-width:80%;
        margin: 0 auto; */
        grid-template-columns:11fr 3fr;
    }

    .content-blog-main {
        /* grid-template-columns:12fr 3fr; */
        width: 100%;
        /* justify-content: center; */
        margin: 0 auto;
        padding-left: 0px;
        /* padding-right: 50px; */
    }
    .content-blog-main .blog-post {
        grid-template-columns:10fr 6fr;
        /* height: 230px; */
    }
    .content-blog-main .blog-post .img-container{
        width: 90%;
        /* aspect-ratio: auto 112 / 112; */
        height: 100%;
    }
    .content-blog-main .blog-post .blog-preview .preview-text{
        display: block;
    }
    .content-blog-main .blog-post .blog-preview .subtitle {
        display: block;
        font-size: 26px;
        margin-bottom: 15px;
    }

    .content-sidebar{
        /* border: 1px solid blue; */
        margin: 20px 30px;
    }
    figcaption{
        width: 75%;
    }
    .blog-article-main-title-index{
        font-size: 34px;
        color: rgb(43, 43, 43);
        line-height: 1.2;
        letter-spacing: 0.1px;
    }
}
@media (min-width: 1200px) {
   .content-blog-main {
        max-width: 80%;
        margin: 0 auto;
        padding-left: 0px;
    }
    .blog-layout-contianer .blog-content{
        width: 80%;
        display: block;
        margin-right: 40px;
        padding-left: 60px;
        padding-right: 60px;
    }
}
