/* .klippy{overflow:visible;position:relative}.btn-klippy{position:absolute;border:0;padding:1px 0 0 1px;background-color:transparent}.btn-klippy>div{margin:0;padding:0}.btn-klippy .octicon{display:inline-block;margin:0;padding:0}.btn-klippy:focus{outline:0}.btn-klippy:focus .octicon{border-width:thin;border-style:dotted;border-color:gray;border-radius:3px} */

.klippy {
    overflow: visible;
    /* position: relative; */
  }

.sourceCode {
    position: relative;
    overflow: auto;
  }
.btn-klippy {
    position: absolute;
    right: 0px;
    top: 0px;
    border: 0;
    padding: 1px 0 0 1px;
    background-color: transparent;
    z-index: 1000;

  }

  
  .btn-klippy > div {
    margin: 0;
    padding: 0;
  }
  
  .btn-klippy .octicon {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  
  .btn-klippy:focus {
    outline: 0;
  }
  
  .btn-klippy:focus .octicon {
    border-width: thin;
    border-style: dotted;
    border-color: gray;
    border-radius: 3px;
  }

  .hl-ipython3{
    position: relative;
    overflow: auto;
  }
  