.ebook-grid {
    display: grid;
    grid-template-columns: 1fr;

}
.ebook-sale-section {
    display: grid;
    grid-template-columns:1fr;
    align-items: center;
    justify-items: center;
}

/* Author */
.ebook-grid .about-author {
    margin-top: 20px;
    padding-left: 60px;
    padding-right: 60px;
    display: grid;
    justify-items: center;

}
.ebook-grid .about-author .about-author-title{
    font-size: 28px;
    font-weight: 600;
}
.ebook-grid .about-author .about-author-img{
    /* margin: 0 auto; */
    text-align: center;
}
.ebook-grid .about-author h5 {
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 25px;
}
.ebook-grid .about-author .author-img{
    display: block;
    background-color: #F2F2F2;
    border-radius: 50%;
    width: 95px;
    height: 95px;
}
.ebook-image{
    width: 65%;
    transition: transform 0.2s ease; 
    /* margin: 0 auto; */
}
.ebook-image:hover{
    transform: scale(1.025);
}
.product-price .sample-button{
    /* background-color: #c0c0c0d6; */
    background-color: #47b6acd6;
    width: 160px;
    padding: 6px 10px;
    border-radius: 30px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    margin: 10px auto;
}
.product-price .sample-button:hover{
    background-color: #11b1a4d6;
}
.product-price .order-button {
    color: #ffff;
    width: 160px;
    background-color: #ff6f3c;
    padding: 6px 10px;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    margin: 10px auto;
    margin-top: 30px;
}
.product-price .order-button:hover {
    background-color: #ff581c;
}
#eBook .book-subtitle{
    font-size: 16px;
    width: 80%;
    /* color:#525252; */
    color: #6B6B6B;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0.2px;
}
.info-section{
    /* background-color: rgb(247 247 247); */
    text-align: center;
    padding-bottom: 20px;
}

.info-section .why-this-ebook {
    width: 80%;
    margin: 10px auto;
}
.info-section .why-this-ebook #img-why-this-ebook,
.info-section .why-this-ebook #what-u-learn{
    width: 40%;
    margin-top:20px;
}
.card-block-size {
    width: inherit;
    height: 80px;
}
.grid-container-book {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    /* gap: 30px; */
}
.discount{
    background-color: #CC0C39;
    color: #ffff;
    /* font-size: 2rem; */
    /* font-weight: 600; */
    padding-left: 5px;
    padding-right: 5px;
}
.price{
    color: rgb(253 112 0);
    font-size: 2.4rem;
    font-weight: 700;
}
.original-price {
    color: #2a2a2a;
    text-decoration: line-through;
    font-size: 2.2rem;

}
.price-fraction{
    font-size: 1.4rem;
    font-weight: 700;
    color: rgb(253 112 0);

}


@media (min-width: 500px){
    .product-price .sample-button {
        width: 230px;
        padding: 8px 15px;
        font-size: 22px;
    }
    .product-price .order-button{
        width: 230px;
        padding: 8px 15px;
        font-size: 22px;
    }
   
}
@media (min-width: 758px){
    .grid-container-book{
        /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
        /* grid-template-rows: minmax(370px, 460px) */
    }
    .info-section .why-this-ebook #img-why-this-ebook, 
    .info-section .why-this-ebook #what-u-learn {
        width: 30%;
        margin-top:20px;
    }
}
@media (min-width: 991px){
    .ebook-grid {
        grid-template-columns: 1.5fr 0.3fr;
        gap: 0px;
        margin-top: 20px;
    }
    .ebook-sale-section {
        grid-template-columns:1fr 1fr;
        
    }
    .ebook-image {
        width: 80%;
        /* margin: 0 auto; */
    }
    .product-price{
        margin-left: -100px;
    }
    .ebook-sale-section .ebook-image {
        margin-right: -180px;
    }
    #eBook .book-subtitle{
        font-size: 22px;
        width: 60%;
    }

    /* Author */
    .ebook-grid .about-author {
        margin-top: 50px;
        margin-left: -100px;
        padding-right: 25px;
        padding-left:25px;
        display: inline-block;
        border-left: 2px solid rgba(197, 197, 197, 0.391);
        height: 500px
    }
    .info-section .why-this-ebook #img-why-this-ebook, 
    .info-section .why-this-ebook #what-u-learn{
        width: 30%;
        margin-top:20px;
    }
}

@media (min-width: 1200px) {
    .grid-container-book{
        /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
        grid-template-columns: 0.25fr 0.25fr 0.25fr;
        /* grid-template-rows: minmax(370px, 460px) */
    }
    .info-section .why-this-ebook #img-why-this-ebook, 
    .info-section .why-this-ebook #what-u-learn{
        width: 20%;
        margin-top:20px;
    }

}