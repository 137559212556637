.tooltipped {
    position: relative; }
  
  .tooltipped::after {
    position: absolute;
    z-index: 1000000;
    display: none;
    padding: 5px 8px;
    font: normal normal 11px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-font-smoothing: subpixel-antialiased;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: break-word;
    white-space: pre;
    pointer-events: none;
    content: attr(aria-label);
    background: rgba(27, 31, 35, 0.8);
    /* background: rgba(8, 102, 197, 0.8); */
    border-radius: 3px;
    opacity: 0; }
  
  .tooltipped::before {
    position: absolute;
    z-index: 1000001;
    display: none;
    width: 0;
    height: 0;
    color: rgba(15, 119, 224, 0.8);
    pointer-events: none;
    content: "";
    border: 5px solid transparent;
    opacity: 0; }
  
  @keyframes tooltip-appear {
    from {
      opacity: 1; }
    to {
      opacity: 0; } 
    }
  .tooltipped:hover::before, .tooltipped:hover::after,
  .tooltipped:active::before,
  .tooltipped:active::after,
  .tooltipped:focus::before,
  .tooltipped:focus::after {
    display: inline-block;
    text-decoration: none;
    animation-name: tooltip-appear;
    animation-duration: 1.2s;
    animation-fill-mode: backwards;
    /* animation-timing-function: ease-in; */
    /* animation-delay: 0.4s;  */
  }
  
  .tooltipped-no-delay:hover::before, 
  .tooltipped-no-delay:hover::after
  /* .tooltipped-no-delay:active::before,
  .tooltipped-no-delay:active::after,
  .tooltipped-no-delay:focus::before,
  .tooltipped-no-delay:focus::after  */
  {
    opacity: 1;
    animation: none; 
  }
  /* .tooltipped-no-delay:active::after{
    opacity: 1;
    display: inline-block;
    animation: tooltip-appear 1s ease-in-out forwards;
  } */
  
  .tooltipped-multiline:hover::after,
  .tooltipped-multiline:active::after,
  .tooltipped-multiline:focus::after {
    display: table-cell; }
  
  .tooltipped-s::after,
  .tooltipped-se::after,
  .tooltipped-sw::after {
    top: 100%;
    right: 50%;
    margin-top: 5px; }
  .tooltipped-s::before,
  .tooltipped-se::before,
  .tooltipped-sw::before {
    top: auto;
    right: 50%;
    bottom: -5px;
    margin-right: -5px;
    border-bottom-color: rgba(27, 31, 35, 0.8); 
  }
  
  .tooltipped-se::after {
    right: auto;
    left: 50%;
    margin-left: -15px; }
  
  .tooltipped-sw::after {
    margin-right: -15px; }
  
  .tooltipped-n::after,
  .tooltipped-ne::after,
  .tooltipped-nw::after {
    right: 50%;
    bottom: 100%;
    margin-bottom: 5px; }
  .tooltipped-n::before,
  .tooltipped-ne::before,
  .tooltipped-nw::before {
    top: -5px;
    right: 50%;
    bottom: auto;
    margin-right: -5px;
    border-top-color: rgba(27, 31, 35, 0.8); 
    /* border-top-color: #ffff */

  }
  
  .tooltipped-ne::after {
    right: auto;
    left: 50%;
    margin-left: -15px; }
  
  .tooltipped-nw::after {
    margin-right: -15px; }
  
  .tooltipped-s::after,
  .tooltipped-n::after {
    transform: translateX(50%); }
  
  .tooltipped-w::after {
    right: 100%;
    bottom: 50%;
    margin-right: 5px;
    transform: translateY(50%); }
  .tooltipped-w::before {
    top: 50%;
    bottom: 50%;
    left: -5px;
    margin-top: -5px;
    border-left-color: rgba(27, 31, 35, 0.8); }
  
  .tooltipped-e::after {
    bottom: 50%;
    left: 100%;
    margin-left: 5px;
    transform: translateY(50%); }
  .tooltipped-e::before {
    top: 50%;
    right: -5px;
    bottom: 50%;
    margin-top: -5px;
    border-right-color: rgba(27, 31, 35, 0.8); }
  
  .tooltipped-multiline::after {
    width: max-content;
    max-width: 250px;
    word-wrap: break-word;
    white-space: pre-line;
    border-collapse: separate; }
  .tooltipped-multiline.tooltipped-s::after, .tooltipped-multiline.tooltipped-n::after {
    right: auto;
    left: 50%;
    transform: translateX(-50%); }
  .tooltipped-multiline.tooltipped-w::after, .tooltipped-multiline.tooltipped-e::after {
    right: 100%; }
  
  @media screen and (min-width: 0\0) {
    .tooltipped-multiline::after {
      width: 250px; } }
  .tooltipped-sticky::before, .tooltipped-sticky::after {
    display: inline-block; }
  .tooltipped-sticky.tooltipped-multiline::after {
    display: table-cell; }
  
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .tooltipped-w::after {
      margin-right: 4.5px; } }
  