* {
  /* to make all box width calculate to include the padding size */
  box-sizing: border-box;
  
}

html {
  font-size: 16px; /* You can set it to your desired base font size */
}

main{
  max-width: 100%;
  /* margin: 0 auto center content the horizontally, and no margin on top and bottom  */
  margin: 0 auto;  
}

.container{
  padding: 10px;
  max-width: 100%;
  /* margin: 0 auto center content the horizontally, and no margin on top and bottom  */
  margin: 0 auto; 
}

a{
  text-decoration: none;
  /* color: #222121e0 */
  color: #2fa4e7;
  /* text-decoration: underline; */
  cursor: pointer;
}

.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}
@media only screen and (min-width: 768px) {
  .container {
    padding: 10px;
    max-width: 90%;
    /* margin: 0 auto center content the horizontally, and no margin on top and bottom  */
    margin: 0 auto;  
  }
  
}


@media only screen and (min-width: 1200px) {
  .container {
    padding: 10px;
    max-width: 75%;
    /* margin: 0 auto center content the horizontally, and no margin on top and bottom  */
    margin: 0 auto;  
  }
  
}


body{
  color: #222;/* make the deault color greyish */
  font-family:Roboto, Arial, Helvetica, sans-serif;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height:1.5;
 
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}

/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .col-s-1 {width: 8.33%;}
  .col-s-2 {width: 16.66%;}
  .col-s-3 {width: 25%;}
  .col-s-4 {width: 33.33%;}
  .col-s-5 {width: 41.66%;}
  .col-s-6 {width: 50%;}
  .col-s-7 {width: 58.33%;}
  .col-s-8 {width: 66.66%;}
  .col-s-9 {width: 75%;}
  .col-s-10 {width: 83.33%;}
  .col-s-11 {width: 91.66%;}
  .col-s-12 {width: 100%;}
}


@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
}

@media only screen and (min-width: 992px) {
  /* For desktop: */
  .col-l-1 {width: 8.33%;}
  .col-l-2 {width: 16.66%;}
  .col-l-3 {width: 25%;}
  .col-l-4 {width: 33.33%;}
  .col-l-5 {width: 41.66%;}
  .col-l-6 {width: 50%;}
  .col-l-7 {width: 58.33%;}
  .col-l-8 {width: 66.66%;}
  .col-l-9 {width: 75%;}
  .col-l-10 {width: 83.33%;}
  .col-l-11 {width: 91.66%;}
  .col-l-12 {width: 100%;}
}

@media only screen and (min-width: 1200px) {
  /* For desktop: */
  .col-xl-1 {width: 8.33%;}
  .col-xl-2 {width: 16.66%;}
  .col-xl-3 {width: 25%;}
  .col-xl-4 {width: 33.33%;}
  .col-xl-5 {width: 41.66%;}
  .col-xl-6 {width: 50%;}
  .col-xl-7 {width: 58.33%;}
  .col-xl-8 {width: 66.66%;}
  .col-xl-9 {width: 75%;}
  .col-xl-10 {width: 83.33%;}
  .col-xl-11 {width: 91.66%;}
  .col-xl-12 {width: 100%;}
}
/* for phone screen size*/
h1, .h1 {
  font-weight: 700;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #161E2A;
  font-size: 3.2rem; 
  letter-spacing: 2.5px;
  
}

h2, .h2 {
  font-weight: 700;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #161E2A;
  font-size: 2.5rem;
  letter-spacing: 2.5px;
}

h3, .h3 {
  font-weight: 700;
  line-height: 1.6;
  margin-top: 5px;
  margin-bottom: 0.5rem;
  color: #161E2A;
  font-size:1.6rem; 
 
}

h4, .h4 {
  font-weight: 700;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #161E2A;
  font-size: 1.1rem; 
  letter-spacing: 1.5px;
}
 
p, .p{
  overflow-wrap: break-word;
  font-size: 18px; 
  white-space: pre-wrap!important;
  line-height:1.8rem;
}

@media only screen and (min-width: 768px) {
  h1, .h1 {
    font-weight: 700;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #161E2A;
    font-size: 3.5rem; 
    letter-spacing: 2.5px;
  }
  
  h2, .h2 {
    font-weight: 700;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #161E2A;
    font-size: 2.5rem;
  }
  
  h3, .h3 {
    font-weight: 700;
    line-height: 1.6;
    margin-top: 5px;
    margin-bottom: 0.5rem;
    color: #161E2A;
    font-size: 1.4rem; 
   
  }

  h4, .h4 {
    font-weight: 700;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #161E2A;
    font-size: 1.5rem; 
    letter-spacing: 1.5px;
  }
   
  p, .p{
    overflow-wrap: break-word;
    font-size: inherit; 
  }
}

@media only screen and (min-width: 920px) {
  h1, .h1 {
    font-weight: 700;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #161E2A;
    font-size: 3.5rem; 
    letter-spacing: 2.5px;
  }
  
  h2, .h2 {
    font-weight: 700;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #161E2A;
    font-size: 2.5rem;
    letter-spacing: 2.5px;
  }
  
  h3, .h3 {
    font-weight: 700;
    line-height: 1.6;
    margin-top: 5px;
    margin-bottom: 0.5rem;
    color: #161E2A;
    font-size: 1.6rem; 
    
  }

  h4, .h4 {
    font-weight: 700;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #161E2A;
    font-size: 1.5rem; 
    letter-spacing: 1.5px;
  }
   
  p, .p{
    overflow-wrap: break-word;
    font-size: 22px; 
    line-height:2.5rem;
  }
}

@media only screen and (min-width: 1200px) {
  h1, .h1 {
    font-weight: 700;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #161E2A;
    /* font-size: 4.5vw;  */
    font-size: 4rem;
  }
  
  h2, .h2 {
    font-weight: 550;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #161E2A;
    font-size: 3rem;
    font-weight: 500px;
  }
  
  h3, .h3 {
    font-weight: 700;
    line-height: 1.6;
    margin-top: 5px;
    margin-bottom: 0.5rem;
    color: #161E2A;
    font-size: 1.5rem; 
 
  }

  h4, .h4 {
    font-weight: 700;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #161E2A;
    font-size: 1.5rem; 
  }
   
  p, .p{
    overflow-wrap: break-word;
    font-size: 22px; 
    line-height:2.5rem;
  }
}

.block-section h1,h2{
  color: #ff8a07ed;
  font-weight: 700;
}

.block-section h3{
  color: #4f4f4f8f
}

.main-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 100%;
}

@media only screen and (min-width: 1200px){
  .main-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: 80% 20%;
  }
}

.main-grid h2{
  display: flex;
  
}



img {
  max-width: 100%; /* This defines all img tags should have maxium width of 100% of it's parent's width, so the image will not overflow the parent's width */
}

/* header */

header {
  display: flex; /*make the components sit next to each other instead of stack vertically */
  justify-content: space-between; /*Add space in between*/
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: flex-end; /* aligns the logo and login register to on the same bottom line */
  /* gap:16px; */
  flex-wrap:wrap;
}


header a{
  text-decoration:none;
  color: inherit; /* inherit color from the nearest parent tag of <a>, which is <header> tag here. If no color specified, then brower default color is used. */
}

header a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px; 
  line-height: 25px;
  border-radius: 4px;
}

.header-right {
  float: right;
}




header a.logo {
  font-weight: bold;
  font-size: 1.9rem;
  letter-spacing: 0.5px;
  color: #0cc7be;
  margin-left:3rem;
  
}

/* .nav-item{
  color: #0e0e0e;
  font-size: 1.3rem;
  letter-spacing: 1.3px;
  margin-right: 30px;

} */

header a.user, a.logging {
 color: #0e0e0e;
 font-size: 1.1rem;
 /* font-weight: bold; */
}

/* header */

/* navigation bar */

.topnav {
  overflow: hidden;
  background-color: #fff;
  position:fixed;
  top: 0;

}

.topnav a {
  float: left;
  display: block;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  padding: 17px 16px;
  text-decoration: none;
  font-size: 17px;
  letter-spacing: 1px;
}

.topnav a.logo {
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: 2px;
  color: #0cc7be;
  background-color: #fff;
  padding-left: 5vw;
  padding-top: 13px;
  

}

.nav-links {
  float: left; /* Float the links to the left */
}

.topnav .icon{
  display: none;
}

.subtopnav .drop-icon{
  display:none;
}


.topnav .logo:hover {
  color: #099790;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content a:hover {
  background-color: #0aa8a0;
  color: #f1f1f1;
}

.subtopnav a{
  padding: 15px;
  display: block;
  text-align:center;
}

.subtopnav li{
  float: left;
}

.subtopnav a {
  /* display: inline-block; */
  /* float: left; */
  width: auto;
  margin: 0!important;
  padding: 10px 20px 10px 20px;
  /* font-size: 15px!important; */
  text-decoration: none;
  line-height: 1.5;
  z-index:99999;
}




#logo{
  position: fixed;
  /* position: sticky; */
  text-align: center;
  top: 0px;
  /* padding-top:10px; */
  background-color: #fff;
  z-index: 99999;
  width:100%;
  white-space: nowrap;
  /* margin: top 0!important;; */
  margin-bottom: 0px;
  height: 40px;
}

a.logo {
  font-weight: bold;
  font-size: 28px;
  /* letter-spacing: 2px; */
  color: #000000;
  background-color: #fff;
  /* padding-left: 5vw; */
  /* padding-top: 13px; */
}

.header-topnav{
  position: fixed;
  top:0;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 6%);
  background-color:#ffff;
  width: 100%;
  z-index: 9999;
  margin-top:0px;
  padding-top:0px;
  transition: top 0.3s;
  /* padding-bottom: 8px; */
}

.subtopnav{
  /* position: fixed;
  top: 2px; */
  /* padding-left: 100px; */
  padding-top:5px;
  width: 100%;
  white-space: nowrap;
  /* background-color: #747474!important; */
  background-color: #fff;
  z-index: 9999;
  /* box-shadow: 0px 3px 10px rgb(0 0 0 / 6%); */
  /* margin-top:22px;  */
}
.subtopnav ul{
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  margin-left: 20px;
}

.dropdown {
  /* display: inline-block; */
  /* float: left; */
  overflow: hidden;

}


.dropdown .dropbtn {
  font-size: 18px;  
  letter-spacing: 1.1px!important;  
  border: none;
  outline: none;
  color: black;
  /* background-color: #747474; */
  /* background-color: #0bb8af; */
  background-color: #fff;
  padding: 8px 10px;
  font-family: inherit;
  margin: 0px;
  z-index: 9999;
}
.dropdown .header-r{
  text-decoration: none;
  color: inherit;
  padding: 0px;

}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.058);
  z-index: 9999;
  font-size: 18px;
}



.dropdown .dropbtn:hover {

  background-color:#0aa8a0 ;
  cursor: pointer;
}

.dropdown:hover .dropdown-content{
  display: block;
}


.extra-sublist {
  display: none;

}

/* 
.extra-sublist ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
} */

/* .extra-sublist ul li {
  padding: 8px 12px;
} */

/* .extra-sublist ul li a {
  text-decoration: none;
  color: black;
  display: block;
} */

/* Set background color for li when hovering */
.dropLevel1:hover + .extra-sublist,
.extra-sublist:hover
{
  display: inline-block;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #ffff;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.058);
  z-index: 999
}

/* .extra-sublist ul:hover, .extra-sublist li:hover {
  background-color: #0aa8a0;
} */
/* .extra-sublist a:hover{
  background-color: #0aa8a0;
} */





@media screen and (max-width: 541px) {
  .subtopnav a, .dropdown .dropbtn {
      display: none;
   }
   .subtopnav .drop-icon {
     float: right;
     display: block;
   }
   .subtopnav .drop-icon .dropicon{
     font-size: 24px!important;
     display: block;
   }
  
    .subtopnav.responsive {
      /* position: fixed;
      top: 30px; */
      background-color: #ffffff!important;
    }
    .responsive#headerTop {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    .responsive .flexStart
    {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
    .responsive .flexStart li .dropdown{
      display: block
    }
    .subtopnav .drop-icon{
      position: absolute;
      right: 0;
      top: 0;
    }
  
    .subtopnav.responsive .dropdown {
      float: none;
    }
    .subtopnav.responsive .dropdown-content {
      position: absolute;
    }
    .subtopnav.responsive .dropdown .dropbtn {
      display: block;
      width: 100%;
      text-align: middle;
      padding-top:10px;
    }

    .subtopnav.responsive li{
      float:initial;
    }
    .dropdown-content a {
      display: none;
    }
    .responsive .flexStart .dropdown-content a {
      float: none;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      text-align: left;
    }
  }
  
  @media only screen and (max-width: 650px){
    header {
      display:block;
      margin-top: 20px;
      margin-bottom: 30px;
      align-items: flex-end; /* aligns the logo and login register to on the same bottom line */
      gap:16px;
      flex-wrap:wrap;
    }
  
    header a {
      float: none;
      display: block;
      text-align: left;
    }
    .header-right {
      float: none;
      margin-left:50px;
    }
  }

/* navigation */
/* navigation bar  */

/* left navigation bar  */
@media (max-width: 992px) {
  .nav-container {
    display: none;
    position: fixed; /* Set the navigation bar to a fixed position */
    max-width:210px; /* Make it take up the full width */
    height: 100%;
    background-color: #E7E9EB; /* Background color */
    z-index: 999; /* Set a high z-index to ensure it's on top of other content and clickable*/
    transition: transform 0.3s ease-in-out; /* Add a transition effect */
    overflow-y: auto; 
  }

  .toggle-button {
    display: block!important;
    position: fixed;
    top: 35px;
    z-index: 9999;

  }

}

div.show {
  display:block
}

.nav-container {
  position: fixed;
  /* top: 35px; */
  top: 95px;
  background-color: #E7E9EB; /* Background color */
  max-width: 210px;
  /* z-index: 999;  */
  height: 100vh;
  overflow-x:hidden;
  border-radius: 5px;
}

.toggle-button {
  display: none;
}

.navList{
  padding: 0;
  list-style-type: none;
  position: relative; /* Establish a stacking context */
  /* z-index: 1000; */
  margin:0;
}

.navListItem {
  padding: 2px 15px;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  text-decoration: none!important;

}


.navListItem:hover {
  color: black;
  background-color: #0bb8af;
}
.linkActiveSelected {
  color: black;
  background-color: #0bb8af;
}


a.navLink {
  text-decoration: none!important;
  color: black!important;
  
}


.navHover{
 transition: transform 0.2s ease-in-out;
}
.navHover:hover {
  transform: scale(1.03); /* Scale up the text on hover */
}
/* .gallery-container:hover{
  transform: scale(1.2); 
} */

div.nav-container h3 {
  font-size: 20px;
  margin:0;
  padding: 5px 8px; 
  padding-top:30px;
}

/* left navigation bar end  */

/* Opening section */

.opening {
  display: block;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  /* background-color: #09aea6d6; */
  /* padding: 100px 15px 50px 15px; */
  margin-top:150px;
  margin-bottom: 0px;

 
}
@media only screen and (min-width: 635px){
  .opening {
    display: block;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    /* background-color: #09aea6d6; */
    /* padding: 100px 150px 100px 150px; */
    margin-top:100px;
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 1200px){
  .opening {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    padding-top: 30px;
    /* background-color: #09aea6d6; */
    /* padding: 150px 150px 150px 150px; */
    margin-top:50px;
    margin-bottom: 10px;
   
  }
}


.opening h1, .opening h2, .opening h3 {
  text-align: center;
  margin: 50px;
  /* color: #ffff; */
}

.anim-type {
  font-family: Arial, sans-serif;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  opacity: 0; /* Initially, set opacity to 0 */
  animation: typing 2s linear forwards;
}
.word {
  display: inline-block;
  opacity: 0; /* Initially, set opacity to 0 */
  animation: appear 0.25s ease-in-out forwards;
}
@keyframes typing {
  0% {
    opacity: 0; /* Start with opacity at 0 */
  }
  100% {
    opacity: 1; /* Fully display the text */
  }
}
/* Animation for individual word appearance */
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Apply the 'appear' animation to each word */
.word:nth-child(1) {
  animation: appear 0.5s ease-in-out forwards;
}
.word:nth-child(2) {
  animation: appear 1s ease-in-out forwards;
}
.word:nth-child(3) {
  animation: appear 1.5s ease-in-out forwards;
}
.word:nth-child(4) {
  animation: appear 2s ease-in-out forwards;
}
.word:nth-child(5) {
  animation: appear 2.5s ease-in-out forwards;
}

@media only screen and (min-width: 1000px){
  div.opening-texts {
    padding-left:100px;
    padding-right:100px;
  }
}

/* Opening section */

/* empty space */
div.empty-space{
  padding: 40px;
}
/* empty space */

/* block-section */
.block-section{
  /* text-align: center; */
  margin-top: 20px;
  margin-left: 30px;
  border-left: 7px solid #00cdc678;
}
/* block-section */

/* opening python card section */
.card-container {
  display: flex;
  justify-content: space-between;
  /* border: 2px solid #ccc; */
  padding: 10px;
  font-family: Calibri, Roboto, Arial, Helvetica, sans-serif!important;
  font-weight: bold;
}

.card-section {
  display: flex;
  flex-direction: column;
  /* width: calc(30% - 10px);  */
  border-radius: 10px;
  margin:10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)

}


.card-image img {
  max-width: 100%;
  height: auto;
  /* border: 1px solid #ddd; */
  padding: 5px;
}
.card-block-red {
  /* width: 290px;; */
  max-width:100%;
  height:150px;
  background-color: #C0504D;
}
.card-block-pink {
  /* max-width:100%;
  height:150px; */
  background-color: #FDD8D8;
}
.card-block-blue {

  background-color: #6FD2F6;
}
.card-block-deep-blue {
  background-color: #32A2B1;
}
.card-block-light-green {
  background-color: rgba(114, 255, 114, 0.344);
}

.card-block-yellow {
  /* max-width:100%;
  height:150px; */
  background-color: #FEE9A8;
}

.card-block-grey {
  /* max-width:100%;
  height:150px; */
  background-color: #f0f0f0c2;
}

.card-container div.card-section:hover{
  transform: scale(1.1); /* Adjust the scale factor as needed */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}

@media screen and (max-width: 800px) {
  .card-container {
    flex-direction: column; /* Stack sections vertically */
  }

  .card-section {
    margin-bottom: 20px; /* Add spacing between stacked sections */
  }
}
/* opening python card section */

/* gallery */
div.gallery-grid{
  display: grid;
  gap:1.5rem;
  grid-template-columns: 1fr;
  padding-block: 2rem;
  width: min(95%, 70rem); 
  margin-inline: auto;
}

@media only screen and (min-width: 768px){
  div.gallery-grid{
    display: grid;
    gap:1.5rem;
    grid-template-columns: repeat(3, 1fr);
    padding-block: 2rem;
    width: min(95%, 70rem); 
    margin-inline: auto;
  }
}



div.gallery-item{
  text-align: left;
  padding: 0;
  color: #383737;;
 
}
div.gallery-item a {
  text-decoration: none;
}

div.gallery-grid img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;

}

@media only screen and (min-width:768px) {
  div.gallery-grid img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }  
}
/* gallery */

/* gellry vertical index page */
div.gallery-page {
  display: grid;
  gap:1.5rem;
  grid-template-columns: 1fr;
  padding-block: 2rem;
  width: min(95%, 70rem); 
  margin-inline: auto;
  
}
@media only screen and (min-width: 768px){
  div.gallery-page {
    display: grid;
    gap:1.5rem;
    grid-template-columns: repeat(3, 1fr);
    padding-block: 1.5rem;
    margin-inline: auto;
  }
}

/* gellry vertical index page */

/* gallery index page vertical */
div.post{
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-columns: 0.8fr 1.2fr; */
  gap: 0px;
  margin-bottom: 10px;
}
@media screen and (min-width:700px) {
  div.post{
    grid-template-columns: 0.8fr 1.2fr;
  }
}

div.post div.texts h2 {
  margin:0;
  font-size: 1.5rem;
}
div.post div.texts a{
  text-decoration:none;
  color:inherit
}

div.post p.info{
  margin: 6px 0;
  color: #888;
  font-size: .7rem;
  font-weight: bold;
  display: flex;
  gap:10px;
}
div.post p.info a.author{
  color: #333;
}
div.post p.summary{
  margin: 10px 0;
  line-height: 1.4rem; /* gap in beween lines */
  /* word-wrap: inherit; */
  overflow: auto
}
div.post .image{
  max-width:80%;
}

/* gallery index page vertical*/

/* faq posts*/

/* div.faq{
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 0;
} */

div.faq-grid{
  display: grid;
  gap:1.5rem;
  grid-template-columns: 1fr;
  padding-block: 2rem;
  width: min(95%, 70rem); 
  margin-inline: auto;
}

@media only screen and (min-width: 768px){
  div.faq-grid{
    display: grid;
    gap:1.5rem;
    grid-template-columns: repeat(3, 1fr);
    padding-block: 2rem;
    width: min(95%, 70rem); 
    margin-inline: auto;
  }
}

div.faq {
  padding: 0.5rem;
  /* border: black solid 2px ; */
  border-radius: 0.5rem;
  box-shadow: 2.5rem, 3.75rem, 3rem;
  text-align: center;
}

div.faq img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;

}

@media only screen and (min-width:768px) {
  div.faq img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }  
}


@media only screen and (min-width:768px) {
  div.faq{
    grid-template-columns: 0.5fr 1.2fr;
  }
}



div.faq div.texts h2{
  text-decoration:none;
  color:inherit;
  margin: 0;
}

div.faq div.texts a{
  text-decoration:none;
  color:inherit
}

div.faq .texts {
  overflow:hidden; /*for ellipsis to show*/
}

div.faq p.info{
  margin: 6px 0;
  margin-top: 6px;
  margin-bottom: 30px;
  color: #383737;
  display: flex;
  gap:10px;
  /* For multi-line ellipsis */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;

}

div.faq p.info a.author{
  color: #333;
}
div.faq p.summary{
  margin: 10px 0;
  line-height: 1.4rem; /* gap in beween lines */
  color: #888;
  word-wrap: inherit;
  overflow: auto
}
/* faq posts*/

/* more */
.more {
  margin-top:20px;
  margin-bottom:20px;
  text-align:center;
  text-decoration: none;
}

div.more a {
  text-decoration:none;
}

/* more */

/* FAQ list */
div.faq ul{
  list-style:none;
  font-size:1.5rem;
}
div.faq li {
  margin:1rem;
}
/* FAQ list */

/* eBook */
div.ebook {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: auto;
  overflow: hidden; 
}

div.book-star {
  margin-top: 5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap:10px;
}

div.book-star img{
  max-width: 8%;
}


div.ebook img {
  display: block;
  max-width: 60%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 600px){
  div.ebook img{
    max-width: 40%
  }
  div.book-star img{
    max-width: 6%;
  }
}

@media only screen and (min-width: 850px){
  div.ebook img{
    max-width: 30%
  }
  div.book-star img{
    max-width: 7%;
  }
}




div.ebook-info{
  margin-bottom:50px;
}
/* eBook */

/* forms */
form.login, form.register{
  max-width: 400px;
  margin: 0 auto;
}
input{
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 7px;
  border: 2px solid #ddd;
  background-color: #fff;
  border-radius: 5px;
}
form.login button, form.register button{
  width: 100%;
  display: block;
  background-color: #e36f10;
  border-radius: 5px;
  padding: 7px 0;
  border: 0px #fff;
  color: #fff;
  font-weight: bold;
}

form.login h1, form.register h1{
  text-align: center;
}

form.createPost button {
  width: 100%;
  display: block;
  background-color: #0baf9f;
  /* #ec842f */
  border-radius: 5px;
  padding: 7px 0;
  border: 0px #fff;
  color: #fff;
  font-weight: bold;
}
/* forms */

/* posts-heading */
.posts-heading {
  /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
  font-weight: 600;
  line-height: 1.2;
  /* color: #317eac */
  color: #379aa4;
  letter-spacing: 0.5px;
  /* margin-top:10px; */
  margin-bottom:15px;
}

h1.posts-heading {
  font-weight: bold;
  color: #379aa4;
  margin-bottom: 15px;
}

/* posts-heading */

/* gallery image */
.color-green {
  color: #e1771f
}
/* gallery image */

/* footer */
.footer-section {
  text-align: center;
  color: #4f4f4f;
  margin-bottom: 50px;
  /* margin-top: 250px; */
  font-size: 12px;
  /* overflow: hidden; */
}

.footer-section  a {
  text-decoration: none;
}
.footer-section .section {
  margin: 10px;

}

/* footer */

/* ebook section */
.container#eBook{
  padding: 10px;
  max-width: 100%;
  /* margin: 0 auto center content the horizontally, and no margin on top and bottom  */
  margin: 0 auto; 
  padding-top:10px;
}


@media only screen and (min-width: 768px) {
  .container#eBook {
    padding: 10px;
    max-width: 90%;
    /* margin: 0 auto center content the horizontally, and no margin on top and bottom  */
    margin: 0 auto;  
    padding-top:20px;
  }
  
}


@media only screen and (min-width: 1200px) {
.container#eBook {
    padding: 10px;
    max-width: 85%;
    /* margin: 0 auto center content the horizontally, and no margin on top and bottom  */
    margin: 0 auto;  
    padding-top:20px;
  }
  
}

.eBook-section {
  padding: 30px;
  max-width: 100%;
  /* margin: 0 auto center content the horizontally, and no margin on top and bottom  */
  margin: 0 auto;  
  padding-right: 20px;
  gap:40px;
  
}

h1#eBook {
  font-size: 1.8rem;
  font-weight:500;
  color: #212529;
  line-height: 2;
}


p.texts {
  font-size:1rem;
  font-weight:300;
  letter-spacing: 0.5px;
  color: #212529;
  line-height:1.7
}

div.eBook-img {
  max-width:60%;
  margin:0 auto;
  padding-top:20px;
  padding-bottom:100px;
}

p.author {
  color: gray;
  font-size: 18px;
  padding-bottom: 15px;;
}

@media only screen and (min-width: 865px) {
  .eBook-section {
    display: grid;
    grid-template-columns: 1fr 0.9fr;
    padding: 10px;
    max-width: 100%;
    /* margin: 0 auto center content the horizontally, and no margin on top and bottom  */
    margin: 0 auto;  
    padding-right: 20px;
    gap:40px;
    
  }

  h1#eBook {
    font-size: 2rem;
    font-weight:500;
    color: #212529;
    line-height: 1.2;
  }
  
  div.intro#eBook {
    padding-right:20px;
  
  }
  p.texts {
    font-size:1.2rem;
    font-weight:300;
    letter-spacing: 0.6px;
    color: #212529;
    line-height:1.5
  }
  
  div.eBook-img {
    max-width:85%;
    margin:0 auto;
    padding-top:20px;
    padding-bottom:100px;
  }

  p.author {
    color: gray;
    font-size: 20px;
    padding-bottom: 15px;
    padding-top:0;
  }
}
div.eBook-section a {
  text-decoration: none;
}

div.eBook-section .btn {
  color: #ffff;
  background-color: #Fb8500;
  font-weight: 500;
  border-radius: 0.3rem;
  line-height: 1.2;
  width: 70%;
  text-align: center;
  padding: 0.5rem 1rem;
 
  /* div.more button hover color */
  &:hover {
    color: #ffff;
    background-color: #f08103;
    border-color: #f08103;
  }
}

div.btn-sample {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center;
}



@media only screen and (min-width: 1050px) {
  div.eBook-section .btn {
    color: #ffff;
    background-color: #Fb8500;
    font-weight: 500;
    border-radius: 0.3rem;
    line-height: 1.6;
    width: 70%;
    text-align: center;
    padding: 0.5rem 1rem;
   
    /* div.more button hover color */
    &:hover {
      color: #ffff;
      background-color: #f08103;
      border-color: #f08103;
    }
  }
  
  div.btn-sample {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center;
  }

}

p.texts img{
  max-width: 6%;
}

div.action {
  margin-top:50px;
}



/* ebook section */

/* ads  */
.ads-right {
  position: static; 
  width: 80%; /* Take the entire width of the parent div */
  text-align: center; /* Center the content horizontally */
  /* height:1000px; */
}

.ads-right .book-star img {
  max-width: 8%
}
.ads-right .book-img {
  width: 80%;
}
.ads-right h5 {
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
}

@media (min-width: 758px){
  .ads-right {
    width: 70%; /* Take the entire width of the parent div */
    
  }
 .ads-right p{
   font-size: 24px;
 }
 .ads-right h5{
   font-size: 24px;
   margin-top:10px;
   margin-bottom: 10px;
   
 }
 .ads-right p{
   overflow: auto;
   text-align: center;
 }
 .ads-right .book-img{
  width: 65%;
}
.ads-right .book-star img {
  max-width: 7%
}

}

@media (min-width: 991px){
.ads-right {
  position: fixed;
  top: 50px;
  right: 10px;
  width: 250px;
  text-align: center;
  z-index: 1;
  padding-left: 10px;
  margin:0; 
  border-left: 1px solid #0e0e0e16;
  height:1000px;
  }
.ads-right p{
  overflow: auto;
  text-align: left;
}
.ads-right h5{
  font-size: 24px;
}
.ads-right .book-img{
  width: 100%;
}
.ads-right .book-star img {
  max-width: 10%
}
}

@media (min-width: 1200px) {
  .ads-right {
    position: fixed;
    top: 50px;
    right: 10px;
    width: 270px; 
    text-align: center;
    z-index: 1;
    padding-left:15px;
    margin-right: 5px;
    border-left: 1px solid #0e0e0e16;
  }
  .ads-right p{
    overflow: auto;
    text-align: left;
  }
  .ads-right .book-img{
    width: 100%;
  }
  .ads-right .book-star img {
    max-width: 9%
  }
  }

/* ads-left */
.ads-left {
  position: fixed;
  top: 100px;
  left: 10px;
  width: 200px;
  text-align: center;
  z-index: 1;
  padding-right:15px;
  margin: 0 10px;
  border-right: 1px solid #0e0e0e24;
  height:500px;
}
.ads-left p{
  overflow: auto;
  text-align: left;
}

@media (max-width: 1200px) {
  .ads-left {
    position: fixed;
    top: 100px;
    left: 10px;
    width: 180px;
    text-align: center;
    z-index: 1;
    padding-right: 10px;
  }
  .ads-left img{
    width:80%;
  }
  .ads-left p{
    overflow: auto;
    text-align: left;
  }
}

@media (max-width: 991px) {
  .ads-left {
      position: static; /* Change to static positioning on smaller screens */
      width: 100%; /* Take the entire width of the parent div */
      text-align: center; /* Center the content horizontally */
      border-right:none;
      height:auto;
      
  }
  .ads-left img{
    width:30%;
  }
  .ads-left p{
    overflow: auto;
    text-align: center;
  }
}


@media (max-width: 769px) {
  .ads-left {
      position: static; /* Change to static positioning on smaller screens */
      width: 100%; /* Take the entire width of the parent div */
      text-align: center; /* Center the content horizontally */
      border-right:none;
      height:auto;
  }
  .ads-left img{
    width:50%
  }
}
/* ads-left */

/* ads-top */
.ads-top {
  margin-top: 20px;
  margin-bottom: 100px;
}
/* ads-top */

/* ads-middle */
.ads-mid {
  margin-top: 20px;
  margin-bottom: 20px;
}
/* ads-middle */

/* ads-bttm */
.ads-mid {
  margin-top: 50px;
  margin-bottom: 50px;
}
/* ads-bttm */



/* ads */

/* image sytle control for each individual post */
.post-img {
  display: block;
  margin: auto;
  width: 100%;
}
.python-img {
  width: 100%;
}

@media (min-width: 700px) {
  .post-img {
    width: 65%;
  }
}
@media (min-width: 990px) {
  .post-img {
    width: 75%;
  }
}
@media (min-width: 1200px) {
  .post-img {
    width: 80%;
  }
}


/* image sytle control for each individual post */


/* button */
.button {
  color: #ffff;
  background-color: #09aea6d6;
  padding: 0.5rem 0.5rem;
  border-radius: 0.7rem;
  display:block;
  width:130px;
  margin: auto;
  font-size:18px;
  border: none;
}
.button:hover {
    color: #ffff;
    background-color: #09aea6;
    border-color: #09aea6;
    cursor: pointer;
}

.button-gray {
  /* color: #616060f8; */
  background-color: #c0c0c0d6;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  cursor: pointer;

}
.button-gray:hover {
    color: #5f5f5ff8;
    background-color: #dbe2e1;
  }

.button-green {
  color: #ffff;
  background-color: #42a79e;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;

}
.button-green:hover {
    color: #ffff;
    background-color: #3b9a92;
    border-color: #3b9a92;
  }

.button-yellow {
  color: black;
  background-color: #FFF4A3;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  display:block;
  width:200px;
  margin: auto;

}
.button-yellow:hover {
    color: #ffff;
    background-color: #09aea6;
    border-color: #09aea6;
  }

.button-orange {
  color: #ffff;
  background-color: #ff6f3c;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  display:block;
  /* width:200px; */

}
.border-radius-30 {
  border-radius: 30px;
}

.button-orange:hover {
    color: #ffff!important;
    background-color: #ee6837;
    /* border-color: #09aea6; */
    cursor: pointer;
  }
.button-pink {
  color: black;
  background-color: #fb9aa3;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  display:block;
  width:200px;
  margin: auto;
}
.button-pink:hover {
    color: black;
    background-color: #ffa8b2;
    border-color: #e3a9af;
  }

.button-dark {
  color: white;
  background-color: #282A35;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  display:block;
  width:200px;
  margin: auto;
}
.button-dark:hover {
    color: #ffff;
    background-color: #363e64;
    border-color: #363e64;
  }
.button-3c {
  color: #ffff;
  background-color: #09aea6d6;
  padding: 0.5rem 0.5rem;
  border-radius: 0.3rem;
  display:block;
  width:200px;
  margin: auto;
}
.button-3c:hover {
  color: #ffff;
  background-color: #09aea6;
  border-color: #09aea6;
}

/* button */

.bg-grey {
  background-color:#E7E9EB
}
.bg-light-grey {
  background-color:rgb(247 247 247);
}
.bg-white {
  background-color: white;
}

.gallery-container {
  width: 100%;
  height: 100%;
}
.image-post{
  height: 300px;
}
.image{
  height: 280px;
}

/* utilities */
.fs-13{
  font-size:13px;
}
.fs-14{
  font-size:14px;
}
.fs-16{
  font-size:16px!important;
}
.fs-18{
  font-size: 18px!important;
}
.fs-19{
  font-size: 19px;
}
.fs-20 {
  font-size:20px; 
}
.fs-22 {
  font-size:22px; 
}
.fs-24 {
  font-size:24px; 
}
.fs-30{
  font-size: 30px;
}
.fs-32 {
  font-size:32px; 
}
.fs-40 {
  font-size:40px; 
}
.fs-50 {
  font-size:50px; 
}
.fs-80{
  font-size: 80px;
}
.fs-100{
  font-size: 100px;
}
@media (min-width: 1200px) {
  fs-xl-40 {
    font-size: 40px;
  }
  fs-xl-42 {
    font-size: 42px;
  }
  fs-xl-42 {
    font-size: 42px;
  }
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}
.fw-800{
  font-weight: 800;
}
.fw-900{
  font-weight: 900;
}

.lh-30{
  line-height:30px;;
}

/* margin */
.mg-0{
  margin:0
}
.mg-t-md {
  margin-top: 20px;
}
.mg-t-0 {
  margin-top: 0px
}
.mg-t-3 {
  margin-top: 3px
}
.mg-t-5 {
  margin-top: 5px
}
.mg-t-10 {
  margin-top: 10px
}
.mg-t-10 {
  margin-top: 10px
}
.mg-t-15 {
  margin-top: 15px
}
.mg-t-20 {
  margin-top: 20px!important;
}
.mg-t-25 {
  margin-top: 25px;
}

.mg-t-30 {
  margin-top: 30px
}
.mg-t-35 {
  margin-top: 35px
}
.mg-t-40 {
  margin-top: 40px
}
.mg-t-50 {
  margin-top: 50px
}
.mg-t-60 {
  margin-top: 60px
}
.mg-t-70 {
  margin-top: 70px
}
.mg-t-80 {
  margin-top: 80px
}
.mg-t-100 {
  margin-top: 100px
}
.mg-t-150 {
  margin-top: 150px
}
.mg-t-250 {
  margin-top: 250px
}
.mg-l-5{
  margin-left: 5px;
}
.mg-l-10{
  margin-left: 10px;
}
.mg-l-20 {
  margin-left: 20px;
}
.mg-l-50 {
  margin-left: 50px!important;
}
.mg-l-50 {
  margin-left: 70px;
}
.mg-l-100 {
  margin-left: 100px!important;
}
.mg-l-150 {
  margin-left: 150px;
}
.mg-r-5{
  margin-right: 5px!important;
}
.mg-r-20{
  margin-right: 20px;
}
.mg-r-50 {
  margin-right: 50px!important;
}
.mg-r-100 {
  margin-right: 100px!important;
}
.mg-r-150 {
  margin-right: 150px;
}
.mg-b-0 {
  margin-bottom:0px;
}
.mg-b-1 {
  margin-bottom:2px;
}
.mg-b-5 {
  margin-bottom:5px;
}
.mg-b-10 {
  margin-bottom:10px;
}
.mg-b-20 {
  margin-bottom:20px;
}
.mg-b-30 {
  margin-bottom:30px;
}
.mg-b-40 {
  margin-bottom:40px;
}
.mg-b-50 {
  margin-bottom:50px;
}
.mg-b-150 {
  margin-bottom:150px!important;
}
.mg-b-250 {
  margin-bottom:250px!important;
}
.mg-auto {
  margin-left: auto!important;
  margin-right: auto!important;
}

/* .p-l-100 {
  padding-left: 100px!important;
}

.p-r-100 {
  padding-right: 100px!important;
} */

.p-0{
  padding:0
}
.p-5{
  padding:5px;
}
.p-10{
  padding:10px;
}
.p-20{
  padding:20px;
}
.p-50{
  padding:50px
}
.p-l-0{
  padding-left:0
}
.p-l-5{
  padding-left: 5px;
}
.p-l-8{
  padding-left: 8px;
}
.p-l-10{
  padding-left: 10px;
}
.p-l-50{
  padding-left: 50px;
}
.p-r-0{
  padding-right:0
}
.p-r-5{
  padding-right: 5px;
}
.p-r-8{
  padding-right: 8px;
}
.p-r-10{
  padding-right: 10px;
}
.p-r-15{
  padding-right: 15px;
}

.p-t-5{
  padding-top: 5px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-t-100 {
  padding-top: 100px;
}
.p-t-250 {
  padding-top: 250px;
}
.p-b-5{
  padding-bottom: 5px;
}

/* width */
.width-5 {
  width:5%!important;
}
.width-20 {
  width:20%!important;
}
.width-200{
  width: 200px!important;
}

@media only screen and (max-width: 510px){
  .w-sm-60{
    width: 60%;
  }
}

@media only screen and (max-width:768px) {
  .mg-t-md-50{
    margin-top: 50px
  }

  .mg-t-md-100{
    margin-top: 100px
  }

  .w-md-100{
    width: 100%
  }

  .margin-md-auto{
    margin:auto;
  }
  .float-md-none{
    float: none;
  }
}


@media only screen and (max-width:993px) {
  .w-ll-40{
    width: 40%;
  }
  .w-ll-50{
    width: 50%
  }
  .w-ll-70{
    width:70%
  }
  .w-ll-80{
    width:80%
  }
  .margin-lg-auto{
    margin-left:auto;
    margin-right: auto;
  }
  .float-lg-none{
    float: none;
  }
}

@media only screen and (min-width:510px) {
  .p-m-0{
    padding:0px!important;
  }
  .p-m-10{
    padding:10px!important;
  }
  .block {
    flex-basis: calc(33.333% - 60px);
    margin: 20px;
  }
  .w-ms-30{
    width:30%
  }
  .w-ms-50{
    width: 50%!important;
  }
  .mg-l-m-50{
    margin-left: 50px;
  }

}

@media only screen and (min-width: 768px) {
  .p-md-20{
    padding:20px!important;
  }
  .p-l-md-30 {
    padding-left: 30px!important;
  }
  .p-l-md-50 {
    padding-left: 50px!important;
  }
  .p-l-md-60{
    padding-left: 60px;
  }
  .p-l-md-100 {
    padding-left: 100px!important;
  }
  .p-r-md-60 {
    padding-right: 60px!important;
  }
  .p-r-md-80 {
    padding-right: 80px!important;
  }
  .p-r-md-100 {
    padding-right: 100px!important;
  }
  .p-t-md-80 {
    padding-top: 80px!important;
  }
  .p-t-md-90 {
    padding-top: 90px!important;
  }
  .p-t-md-100 {
    padding-top: 100px!important;
  }
  .w-l-40{
    width: 40%!important;
  }
  .w-l-50 {
    width: 50%!important;
  }
  .w-l-70 {
    width: 70%!important;
  }
  .w-l-80 {
    width: 80%!important;
  }
  .mg-t-l-30{
    margin-top: 30px;
  }

  
}

@media only screen and (min-width:993px) {
  .mg-large-left-50{
    margin-left: 50px
  }
  .mg-large-left-100{
    margin-left: 100px
  }
  .flex-width-large-3 {
    width: calc(30% - 10px); 
  }
  .w-sl-20{
    width:20%!important;
  }
  .w-sl-60{
    width: 60%!important;
  }
  .w-sl-70{
    width: 70%!important;
  }
  .mg-t-sl-250{
    margin-top:250px
  }
  .fs-sl-32{
    font-size:32px;
  }
}


@media only screen and (min-width: 1200px) {
  .w-xl-12{
    width:12%!important;
  }
  .w-xl-15{
    width:15%!important;
  }
  .w-xl-20{
    width: 20%!important;
  }
  .w-xl-25{
    width: 25%!important;
  }
  .w-xl-40 {
    width: 40%!important;
  }
  .w-xl-50 {
    width: 50%!important;
  }
  .w-xl-60 {
    width: 60%!important;
  }
  .w-xl-65 {
    width: 65%!important;
  }
  .w-xl-70 {
    width: 70%
  }
  .w-xl-80 {
    width: 80%
  }
  .w-xl-85 {
    width: 85%
  }
  .margin-top-xlarge-30{
    margin-top: 30px;}
  .p-xl-10{
    padding:10px;
  }
}


/* font-size */


/* width */
.w-10{
  width:10%
}
.w-15{
  width:15%
}
.w-20{
  width:20%
}
.w-30{
  width:30%;
}
.w-40 {
  width: 40%!important;
}
.w-50 {
  width: 50%!important;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-100 {
  width: 100%;
}
.width-170 {
  width: 170px!important;
}
.width-180 {
  width: 180px!important;
}
.width-200 {
  width: 200px
}
.width-250 {
  width: 250px!important;
}
.h-10 {
  height: 10%;
}
.h-15 {
  height: 15%;
}
.h-20 {
  height: 20%;
}
.h-30 {
  height: 30%;
}
.h-40 {
  height: 40%;
}

.fc-white {
  color: white!important;
}
.fc-light-grey{
  color: #363636!important;
}
.fc-super-light-grey{
  color: rgb(158, 158, 158);
}
.fc-turquoise{
  color: #379aa4
}
.fc-darkBlue {
  color: #315866
}

.bj{
  color: #242424;
}
.z {
  line-height: 20px;
  letter-spacing: 0px;
}
.dn {
  color: #6B6B6B;
}
.fs-15 {
  font-size: 15px;
}
.be {
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 2rem;
}


/* text center */
.text-center {
  text-align: center;
}
.text-left{
  text-align: left;
}

/* item center */
.item-center {
  align-items: center!important;
}

.a-black {
  color: rgb(37, 37, 37);
}
.a-black:hover{
  color: rgb(37, 37, 37);
}

/* display flex */
.flexSpaceBetween{
  display:flex;
  justify-content: space-between; 
}
.flexSpaceBetweenCenter{
  display:flex;
  justify-content: space-between; 
  align-items: center;
  flex-wrap: wrap;
}
.flexCenterAround{
  display:flex;
  justify-content: space-around; 
  align-items: center;
}
.flexCenter{
  display: flex!important;
  justify-content: center;
  align-items: center;
}
.flexCenter-horizontal {
  display: flex!important;
  justify-content: contenr!important;
}
.flexCenter-vertically {
  display: flex;
  align-items: center;
  height: 100%; 
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: baseline;
}
.flexStart-center{
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.flexStart-flexstart{
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: flex-start;
}


#flex{
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  /* align-items:stretch; */
}
@media screen and (min-width: 763px)  {
  #flex{
    display: flex;
    flex-wrap: nowrap
  }
}


.letter-space-min {
  letter-spacing: 0.1px;
}



.slider-container {
  /* background-color: #f0f0f0;  */
  padding: 20px; /* Add padding for spacing */
  width:100%;
}

.slide {
  background-color: #ffffff; 
  padding: 20px; 
  text-align: center;

}
.slide img {
  display: block;
  max-width: 100%; /* Make the image fill the entire slide */
  height: auto; /* Maintain aspect ratio */
}
.slick-prev,
.slick-next {
  font-size: 24px; /* Adjust the font size */
  color: #000000; /* Set the color of the buttons */
}

.slick-prev:hover,
.slick-next:hover {
  color: #333333; /* Set the color of the buttons on hover */
}

.slick-prev {
  left: 10px; /* Adjust the position of the previous button */
  z-index: 1; /* Ensure the buttons appear above the slides */
}

.slick-next {
  right: 10px; /* Adjust the position of the next button */
  z-index: 1; /* Ensure the buttons appear above the slides */
}

/* Styling for the custom arrow buttons */
.custom-prev-arrow,
.custom-next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 10px;
}

.custom-prev-arrow {
  left: 10px;
}

.custom-next-arrow {
  right: 10px;
}

