.container-landing {
    /* border: 1px solid red; */
    width: 90%;
    margin: 0 auto;
}
.container-landing #data-wrangling h1 {
    font-size: 28px;
}
.container-landing .banner-rpackages {
    max-width: 100%;
    margin: 10px auto;
    /* transition: transform 0.2s ease;  */
}

.container-landing .buttons{
    margin: 30px auto;
    text-align: center;
    background-color: #239de4;
    max-width: 250px;
    border-radius: 25px;
    
}
.container-landing .buttons:hover{
    background-color: #1677ae;
}
.container-landing #data-wrangling .buttons .button-text{
    margin: 0 auto;
    /* text-align: center; */
    color: white;
    padding: 10px 10px;
    font-weight: 700;
    font-size: 20px;
    
    
}
.gallery-section h1 {
    font-size: 28px;
}

.gallery-section .banner-gallery {
    max-width: 80%;
    margin: 0 auto;
    transition: transform 0.2s ease; 
}
.gallery-section .banner-gallery:hover {
    transform: scale(1.025);
}
.gallery-section {
    background-color: rgba(228, 228, 228, 0.366);
    padding-top: 30px;
    padding-bottom: 30px;
}

.layout .card-layout {
    /* box-shadow: 2px 2px 2px rgb(0 0 0 / 10%); */
    border-radius: 20px;
    /* transition: transform 0.2s ease;  */
    width: 70%;
    margin: 0 auto;
    /* height: 90%; */
    margin-bottom: 20px;
}

.layout .card-layout .card-image{
    /* box-shadow: 2px 2px 2px rgb(0 0 0 / 10%); */
    text-align: center;
    height: 50%;
}
.layout .card-layout .visual-image{
    width: 50%
}

.layout .card-layout #bottom h3{
    padding-top:10px;
    padding-bottom:10px;
}
.gallery-section .buttons {
    margin: 15px auto;
    text-align: center;
    background-color: #239de4;
    max-width: 300px;
    border-radius: 25px;
    color: white;
    /* padding-left: 20px;
    padding-right: 20px; */
    padding: 8px 25px;
    font-weight: 700;
    font-size:20px;
}
.gallery-section .buttons:hover{
    background-color: #1677ae;
}
#data-wrangling .r-logo{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 10px;
}
#data-wrangling .r-logo img{
   width: 100px;
}



@media (min-width: 758px) {
    .container-landing .banner-rpackages{
        max-width: 50%;
    }
    .layout .card-layout {
        max-width: 70%;
        margin-right:40px;
    }
    #r-gallery .banner-gallery {
        max-width: 75%;
        margin-left:20px;
    }

    .gallery-section .layout {
        display: grid;
        grid-template-columns:0.8fr 1.2fr;
        justify-items: center;
        align-items: center;
    }
    .layout .card-layout .visual-image{
        width: 85%
    }
    .gallery-section .buttons {
        font-size:24px;
    }
    .container-landing #data-wrangling .buttons .button-text{
        font-size:24px;
    }
     
}
@media (min-width: 1141px) {
    .layout .card-layout {
        max-width: 45%;
        margin-right:40px;
    }
    #data-wrangling .r-logo img{
        width: 130px;
     }
}