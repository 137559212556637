/* section colors */
.blk-light-pink{
    background-color: #F3ECEA;
    color:black;
}
.blk-turquoise {
    background-color: #96D4D4;
    color: black;
}
.blk-yellow{
    background-color: #FFF4A3;
    color: black;
}
.blk-light-green {
    background-color: #D9EEE1;
    color: black;
}
.blk-black{
    background-color: #273145;
    color: white;
}
.blk-padding-32{
    padding-top: 32px;
    padding-bottom: 32px;
}
.blk-content{
    max-width:900px;
    margin-left:auto;
    margin-right: auto;
}