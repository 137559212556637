@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lobster&family=Oswald:wght@200..700&family=Ultra&family=Freeman&display=swap');


.logo-font {
    font-family: "Kanit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800!important;
    font-style: normal;
    font-size: 22px!important;
}
.fontfamily-freeman {
    font-family: 'Freeman', 'Roboto', sans-serif, Arial, Helvetica;
    line-height: 1;
    letter-spacing: 2.5px;
    font-weight:400;
    font-style: normal;
}